import React from 'react'
import { Link } from 'gatsby'
import { RichText } from 'prismic-reactjs'

const ImageHighlight = ({ slice }) => (
  <div class="columns">
    <div class="column is-half">
      <div class="content is-medium">
        <div class="is-family-secondary">
          <RichText render={slice.primary.title.raw} />
        </div>
        <RichText render={slice.primary.description.raw} />
        {slice.primary.link ? (
          <p>
            <Link to={slice.primary.link.url}>
              {RichText.asText(slice.primary.link_label.raw)}
            </Link>
          </p>
        ) : null}
      </div>
    </div>
    <div class="column is-half">
      <div class="card">
        <div class="card-image">
          <figure class="image">
            <img
              src={slice.primary.featured_image.url}
              alt={slice.primary.featured_image.alt}
            />
            <div dangerouslySetInnerHTML={{__html: slice.primary.featured_image.copyright}} />
          </figure>
        </div>
      </div>
    </div>
  </div>
)

export default ImageHighlight
