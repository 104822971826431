import React from 'react'

const Banner = ({ slice }) => (
  <section
    class="hero is-fullheight"
    style={{
      backgroundImage: `linear-gradient(rgba(7, 17, 8, 0.4), rgba(7, 17, 8, 0.8)), url(${slice.primary.background.url})`,
      backgroundPosition: `50% 50%`,
    }}
  >
    <div class="hero-body">
      <div class="container is-max-desktop">
        <figure class="image block" width="640">
          <img src={slice.primary.logo.url} alt={slice.primary.logo.alt} />
        </figure>
        <div class="title has-text-white has-text-centered is-size-2 is-size-5-mobile">
          {slice.primary.description.text}
        </div>
      </div>
      <div dangerouslySetInnerHTML={{__html: slice.primary.background.copyright}} />
    </div>
  </section>
)

export default Banner
