import React from 'react'

const FullWidthImage = ({ slice }) => (
  <div class="columns">
    <div class="column is-full">
      <figure class="image is-3by1">
        <img
          src={slice.primary.full_width_image.url}
          alt={slice.primary.full_width_image.alt}
        />
        <div dangerouslySetInnerHTML={{__html: slice.primary.full_width_image.copyright}} />
      </figure>
    </div>
  </div>
)

export default FullWidthImage
