import React from 'react'
import { RichText } from 'prismic-reactjs';

const Quote = ({ slice }) => (
  <div class="columns">
    <div class="column is-full">
      <article class="message is-primary is-large">
        <div class="message-header">
            <RichText render={slice.primary.quote.raw} />
        </div>
      </article>
    </div>
    
  </div>
)

export default Quote
