import React from 'react'
import { Link } from 'gatsby'
import { RichText } from 'prismic-reactjs'

const ImageGallery = ({ slice }) => {
  return (
    <div>
    <div class="content is-family-secondary">
      <RichText render={slice.primary.gallery_title.raw} />
    </div>
    <div class="columns">
        {slice.items.map((galleryItem, index) => (
          <div class="column">
          <div class="card" key={`gallery-item=${index}`}>
            <div class="card-image">
              <figure class="image is-4by3">
                <img src={galleryItem.image.url} alt={galleryItem.image.alt} />
                <div dangerouslySetInnerHTML={{__html: galleryItem.image.copyright}} />
              </figure>
            </div>
            <div class="card-content">
              <div class="content">
                <RichText render={galleryItem.image_description.raw} />
                {galleryItem.link && galleryItem.link ? (
                  <p className="gallery-link">
                    <Link to={galleryItem.link.url}>
                      {RichText.asText(galleryItem.link_label.raw)}
                    </Link>
                  </p>
                  ) : null}
              </div>
            </div>
          </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default ImageGallery
