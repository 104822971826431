import React from 'react'
import Banner from './slices/Banner'
import FullWidthImage from './slices/FullWidthImage'
import ImageGallery from './slices/ImageGallery'
import ImageHighlight from './slices/ImageHighlight'
import Quote from './slices/Quote'
import Text from './slices/Text'
import Contact from './slices/Contact'

const SliceZone = ({ sliceZone }) => {
  const sliceComponents = {
    full_width_image: FullWidthImage,
    image_gallery: ImageGallery,
    image_highlight: ImageHighlight,
    quote: Quote,
    text: Text,
    contact: Contact
  }

  const sliceBannerComponents = {
    banner: Banner,
  }

  const sliceZoneContent = sliceZone.map((slice, index) => {
    const SliceComponent = sliceComponents[slice.slice_type]
    if (SliceComponent) {
      return (
        <section class="section" key={`section-${index}`}>
          <SliceComponent slice={slice} key={`slice-${index}`} />
        </section>
      )
    }
    return null
  })

  const sliceBannerZoneContent = sliceZone.map((slice, index) => {
    const SliceComponent = sliceBannerComponents[slice.slice_type]
    if (SliceComponent) {
      return <SliceComponent slice={slice} key={`slice-${index}`} />
    }
    return null
  })
  
  return (
    <div>
      {sliceBannerZoneContent}
        <div class="container">
          {sliceZoneContent}
        </div>
    </div>)
}

export default SliceZone
