import React from 'react'
import { RichText } from 'prismic-reactjs';

const Text = ({ slice }) => {

  return (
    <div class="columns">
      <div class={`column`}>
        <div class="content is-medium">
          <div class="is-family-secondary">
            <RichText render={slice.primary.title.raw} />
          </div>
          {slice.items.map((item, index) => (
            <RichText render={item.content.raw} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default Text
